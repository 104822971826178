@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background: linear-gradient(rgb(21, 27, 32), rgb(29, 38, 43));
  @apply text-white;
}
