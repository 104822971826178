/* Sass */
.soundwave {
  width: 100%;
  height: 100%
}

.circle {
  position: absolute;
  justify-content: center;
  width: 5px;
  height: 10px;
  border-radius: 10px;
  background-color: rgb(103 232 249);
  animation: move-the-circle 40s infinite;
  transform-origin: center center;

}

.circle:hover {
  background-color: rgb(255, 106, 81);
}

@for $num from 1 through 64 {
  .circle:nth-child(#{$num}) {
    left: ($num - 1) * 10px;
    animation-delay: $num * .1s;
  }
}

@keyframes move-the-circle {
  0% {
    transform: scaleY(100%);
  }
  10% {
    transform: scaleY(-100%)
  }
  20% {
    transform: scaleY(25%);
  }
  30% {
    transform: scaleY(-10%);
  }
  40% {
    transform: scaleY(40%);
  }
  50% {
    transform: scaleY(-30%);
    background-color: rgb(255, 106, 81);
  }
  60% {
    transform: scaleY(-60%)
  }
  70% {
    transform: scaleY(-50%);
  }
  80% {
    transform: scaleY(20%);
  }
  90% {
    transform: scaleY(-80%);
  }
  100% {
    transform: scaleY(100%);
  }
}